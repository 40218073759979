<template>
  <div class="accountManagement">
    <back />

    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="typeValue" placeholder="选择类型" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button class="btn" type="primary" @click="handleAdd">新增</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="accountNum" label="账号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="accountType" label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.accountType == '1'">支付宝</span>
              <span v-if="scope.row.accountType == '2'">银行卡</span>
            </template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="opeBank" label="开户行"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="bankType" label="账户类型">
            <template slot-scope="scope">
              <span v-if="scope.row.bankType == '1'">对公账户</span>
              <span v-if="scope.row.bankType == '2'">对私账户</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="opeBank" label="银行名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="bankCode" label="联行号"></el-table-column>
          <el-table-column show-overflow-tooltip prop="accountName" label="真实姓名"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
              <el-button size="small" class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
        <el-row class="row">
          <el-col :span="12" class="col">
            <el-form-item label="类型：" prop="accountType">
              <el-radio v-model="ruleForm.accountType" label="1" @input="handleInput">支付宝</el-radio>
              <el-radio v-model="ruleForm.accountType" label="2" @input="handleInput">银行卡</el-radio>
            </el-form-item>
            <!-- 支付宝 -->
            <div v-if="ruleForm.accountType == 1">
              <el-form-item label="支付宝账号：" prop="accountNum">
                <el-input v-model="ruleForm.accountNum" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="真实名称：" prop="accountName">
                <el-input v-model="ruleForm.accountName" placeholder="请输入"></el-input>
              </el-form-item>
            </div>

            <!-- 银行卡 -->
            <div v-if="ruleForm.accountType == 2">
              <!-- <el-form-item label="开户行：" prop="bank">
                <el-input v-model="ruleForm.bank" placeholder="请输入"></el-input>
              </el-form-item> -->
              <el-form-item label="账户类型：" prop="bankType">
                <el-radio v-model="ruleForm.bankType" label="1">对公账户</el-radio>
                <el-radio v-model="ruleForm.bankType" label="2">对私账户</el-radio>
              </el-form-item>
              <el-form-item label="银行名称：" prop="opeBank">
                <el-select v-model="ruleForm.opeBank" placeholder="选择类型" clearable>
                  <el-option v-for="item in bankNameOptions" :key="item.abbreviation" :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开户行联行号：" prop="bankCode">
                <el-input v-model="ruleForm.bankCode" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="卡号：" prop="accountNum">
                <el-input v-model="ruleForm.accountNum" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="真实姓名：" prop="accountName">
                <el-input v-model="ruleForm.accountName" placeholder="请输入"></el-input>
              </el-form-item>
            </div>

          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [{
        value: '1',
        label: '支付宝'
      }, {
        value: '2',
        label: '银行卡'
      },],
      typeValue: '', // 选择类型
      // 表格数据
      tableData: [],

      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      dialogTitle: '新增',
      editId: '',
      toggle: '',
      bankNameOptions: require("../../../assets/bank.json"), // 银行数据
      ruleForm: {
        // type: '', // 类型
        // realName: '', // 真实姓名
        // // bank: '', // 开户行
        // card: '', // 卡号
        // alipayAccount: '', // 支付宝账号
        // bankType: '', // 账户类型
        // bankName: '', //银行名称

        accountName: '', // 账户名称
        accountNum: '', // 账户号码
        accountType: '', // 账户类型
        bankCode: '', // 收款人开户行行号（开户行联行号）
        bankType: '', // 银行卡类型，1对公，2对私
        opeBank: '', // 	开户行（账户类型为银行卡必填）银行卡名称
      },
      rules: {
        accountType: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        accountName: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        accountNum: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        bankCode: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        bankType: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
        opeBank: [{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }],
      },

      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getExtractAccountListPage();
  },
  methods: {
    // 获取列表
    getExtractAccountListPage() {
      this.loading = true;
      let params = {
        accountType: this.typeValue || null, // 选择类型
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.getExtractAccountListPage, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleInput(value) {
      this.ruleForm.accountType = '';
      this.resetForm();
      this.ruleForm.accountType = value;
      if (this.ruleForm.accountType == 2) {
        this.$alert('功能开发中，敬请期待！', '提醒', {
          confirmButtonText: '确定',
          center: true
        }).then(() => {

        }).catch(() => {

        });
      }
      this.$set(this.ruleForm, 'accountType', '1')
    },
    // 新增
    handleAdd() {
      this.dialogVisible = true;
      this.dialogTitle = '新增'
      this.toggle = 'add'
      this.ruleForm.accountType = "1";
    },
    // 编辑
    handleEdit(row) {
      this.editId = row.id;
      this.dialogVisible = true;
      this.dialogTitle = '编辑'
      this.toggle = 'edit';
      // 赋值
      this.ruleForm.accountType = row.accountType
      this.$set(this.ruleForm, 'accountName', row.accountName)
      this.$set(this.ruleForm, 'accountNum', row.accountNum)
      // 银行卡
      if (this.ruleForm.accountType == '2') {
        this.$set(this.ruleForm, 'bankCode', row.bankCode)
        this.$set(this.ruleForm, 'bankType', row.bankType)
        this.$set(this.ruleForm, 'opeBank', row.opeBank)
      }
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(`${this.$api.getExtractAccountDelete}/${row.id}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getExtractAccountListPage()
          })
      }).catch(() => { });
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.toggle = '';
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          let params = {
            accountName: this.ruleForm.accountName,
            accountType: this.ruleForm.accountType,
            accountNum: this.ruleForm.accountNum,
            bankCode: this.ruleForm.bankCode,
            bankType: this.ruleForm.bankType,
            opeBank: this.ruleForm.opeBank,
          };

          // if (this.ruleForm.accountType === '2') {
          //   // 支付宝
          //   params.accountNum = this.ruleForm.accountNum;
          // } else {
          //   // 银行卡
          //   params.accountNum = this.ruleForm.card;
          //   // params.opeBank = this.ruleForm.bank;
          // }

          if (this.toggle === 'add') {
            this.addAccount(params);
          } else {
            this.editAccount(params);
          }
        }
        this.handleCloseDialog();
      });
    },

    addAccount(params) {
      this.$axios.post(this.$api.getExtractAccountAdd, params).then((res) => {
        if (res.data.code === 100) {
          this.$message.success('新增成功!');
          this.getExtractAccountListPage();
        }
      });
    },

    editAccount(params) {
      params.id = this.editId;
      this.$axios.put(this.$api.getExtractAccountUpdate, params).then((res) => {
        if (res.data.code === 100) {
          this.$message.success('编辑成功!');
          this.getExtractAccountListPage();
        }
      });
    },

    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getExtractAccountListPage();
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      this.getExtractAccountListPage();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getExtractAccountListPage();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getExtractAccountListPage();
    },
  }

}
</script>

<style lang="scss" scoped>
.accountManagement {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-right: 20px;
    }

    .el-select {
      margin-right: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }

  .row {
    .col {
      margin-left: 200px;
    }
  }
}
</style>
